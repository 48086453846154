
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'edit-schreiben-modal',
      schreiben: null,
      variablenGefuellt: [],
      variablenUngefuellt: [],
      variablenOhneStatus: [],
      ansprechpartner_name: null,
      ansprechpartner_berufsbezeichnung: null,
      autosave: null,
      showDoku: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(id) {
      this.$$find('schreiben', id).then(element => {
        this.schreibenAbgerufen(element);
        this.autosave = setInterval(this.speichern, 120000);
      });
      this.$bvModal.show(this.modalid);
    },
    speichernUndSchliessen() {
      this.speichern();
      this.close();
    },
    close() {
      clearInterval(this.autosave);
      this.$bvModal.hide(this.modalid);
    },
    hide() {
      clearInterval(this.autosave);
      this.schreiben = null;
    },
    vorschau() {
      this.speichern(true);
    },
    schreibenAbgerufen(schreiben) {
      this.schreiben = schreiben;
      this.variablenSortieren();
    },
    variablenSortieren() {
      // Ansprechpartner extra Felder
      this.ansprechpartner_name = this.schreiben.variablen.find(el => el.name == "ansprechpartner.name").value;
      this.ansprechpartner_berufsbezeichnung = this.schreiben.variablen.find(el => el.name == "ansprechpartner.berufsbezeichnung").value;

      // Variablen bereits gefüllt
      this.variablenGefuellt = this.schreiben.variablen.filter(el => el.gefuellt === true);
      // Variablen noch ungefüllt
      this.variablenUngefuellt = this.schreiben.variablen.filter(el => el.gefuellt === false);
      // Nach altem Konzept quasi
      this.variablenOhneStatus = this.schreiben.variablen.filter(el => el.gefuellt === undefined && !el.name.includes('ansprechpartner'));
    },
    speichern(vorschau = false) {
      let variablen = this.variablenGefuellt;
      variablen = variablen.concat(this.variablenUngefuellt);
      variablen = variablen.concat(this.variablenOhneStatus);
      let ansprechpartner_name = this.schreiben.variablen.find(el => el.name == "ansprechpartner.name");
      ansprechpartner_name.value = this.ansprechpartner_name;
      variablen.push(ansprechpartner_name);
      let ansprechpartner_berufsbezeichnung = this.schreiben.variablen.find(el => el.name == "ansprechpartner.berufsbezeichnung");
      ansprechpartner_berufsbezeichnung.value = this.ansprechpartner_berufsbezeichnung;
      variablen.push(ansprechpartner_berufsbezeichnung);

      let send = this.$copy(this.schreiben);
      send.variablen = variablen;

      this.$$update('schreiben', send, 'Schreiben gespeichert').then(element => {
        // this.schreibenAbgerufen(element);
        if (vorschau) {
          let url = '/api/schreiben/'+this.schreiben.id+'/download';
          this.$nuxt.$emit('view-pdf', {titel: this.schreiben.name, url: url});
        }
        this.$nuxt.$emit('update-schreiben', element);
      });
    }
  },
  mounted() {
    this.$nuxt.$on('store-schreiben', this.start);
  },
  beforeDestroy() {
    this.$nuxt.$off('store-schreiben', this.start);
  }

}
