export const state = () => ({
  letzterClick: {
    0:null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null
  },
})

const getters = {
  getLetzterClick: (state) =>  (page) => {
    return state.letzterClick[page];
  }
}

const mutations = {
  setLetzterClick(state, page) {
    state.letzterClick[page] = this.$moment().format('YYYY-MM-DD HH:mm:ss');
  }
}
const actions = {

}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
