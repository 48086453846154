
import verordnungDetails from '~/mixins/models/verordnungDetails';
export default {
  mixins: [verordnungDetails],
  props: ['verordnung', 'autoabruf'],
  data() {
    return {
      verordnungDetails: this.verordnung,
      abrufkey: 0,
    }
  },
  computed: {},
  watch: {
    verordnung(neu) {
      this.verordnungDetails = neu;
      this.open();
    }
  },
  methods: {
    abrufen() {
      this.$$find('verordnungen', this.verordnungDetails.id).then(response => {
        this.verordnungDetails = response;
        this.abrufkey++;
      })
    },
    open() {
      this.fill(this.verordnungDetails.status);
      if (this.autoabruf) {
        this.abrufen();
      }
    }
  },
  mounted() {
    this.open();
  },

}
