import { render, staticRenderFns } from "./DateiSenden.vue?vue&type=template&id=52c78fc7"
import script from "./DateiSenden.vue?vue&type=script&lang=js"
export * from "./DateiSenden.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalFormulareAuswahlKontakt: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/formulare/auswahl/Kontakt.vue').default,GlobalDisplayKontakt: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/Kontakt.vue').default,ModalsDetailsDateiParallelAnzeigen: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/modals/details/DateiParallelAnzeigen.vue').default})
