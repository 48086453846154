import { render, staticRenderFns } from "./TerminVertretungen.vue?vue&type=template&id=23087a56"
import script from "./TerminVertretungen.vue?vue&type=script&lang=js"
export * from "./TerminVertretungen.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayFachbereichBadge: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/FachbereichBadge.vue').default})
