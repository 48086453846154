
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'create-schreiben-modal',
      param: null,
      zuordnungen: [],
      vorlage: null,
      ansprechperson: null,
      name: null,
      anhang: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    reset() {
      this.zuordnungen = [];
      this.vorlage = this.ansprechperson = this.name = null;
    },
    start(param) {
      this.reset();
      this.$$fill('ansprechpersonen', null, true);
      this.param = param;
      if (param.titel) this.name = param.titel;
      if (param.anhang) this.anhang = param.anhang;
      if (param.vorlage_id) {
        this.$$find('vorlagen', param.vorlage_id).then(vorlage => {
          this.vorlage = vorlage;
        })
      }
      this.zuordnungen.push(param);
      this.$$abrufen('ansprechpersonen', 1, '/api/schreiben/ansprechpersonen?model_type='+param.model_type+'&model_id='+param.model_id);
      this.$bvModal.show(this.modalid);
    },
    erstellen() {
      if (!this.vorlage) return;
      if (this.vorlage.briefkopf && !this.ansprechperson) return;
      if (this.ansprechperson) this.zuordnungen.push({model_id: this.ansprechperson.id, model_type: this.ansprechperson.model_type});
      this.$$store('schreiben', {vorlage_id: this.vorlage.id, zuordnungen: this.zuordnungen, name: this.name, empfaenger: this.ansprechperson, anhang: this.anhang}).then(schreiben =>{
        this.$bvModal.hide(this.modalid);
        this.$nuxt.$emit('store-schreiben', schreiben.id)
      });
    }
  },
  mounted() {
    this.$nuxt.$on('create-schreiben', this.start);
  },
  beforeDestroy() {
    this.$nuxt.$off('create-schreiben', this.start);
  }

}
