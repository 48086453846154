export default {
  methods: {
    terminClass(termin) {
      let klassen = '';
      if (termin.deleted_at) return ' termin-archiv';
      if (termin.abgesagt) klassen += ' termin-absage';
      if (termin.puffertermin) klassen += ' termin-puffertermin';
      if (termin.termintyp == 1) klassen += ' termin-therapiestunde';
      // TODO: Einrichtungen / Adressen, dann hier nochmal korrigieren
      if (termin.ort != null) klassen += ' termin-hausbesuch';
      if (termin.stattgefunden != 0) klassen += ' termin-erfasst';
      if (termin.termintyp == 8) klassen += ' termin-einmaligfrei';
      if (termin.termintyp == 9) klassen += ' termin-dauerhaftfrei';
      if (termin.hat_fall) {
        if (termin.hat_fall.includes(this.$model.id)) klassen += ' termin-hat-fall-therapeut';
        else klassen += ' termin-hat-fall';
      }
      return klassen;
    },
    arbeitsstundeClass(arbeitsstunde) {
      if(arbeitsstunde.trigger_type == 'addbox') return 'arbeitsstunde-addbox align-items-center justify-content-center p-0';
      else return 'flex-column justify-content-between';
      // if (arbeitsstunde.abgesagt) return 'termin-absage';
      // if (arbeitsstunde.hausbesuch != 0) return 'termin-hausbesuch';
    },
  }
}
