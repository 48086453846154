import { render, staticRenderFns } from "./FallModal.vue?vue&type=template&id=a23d40e2"
import script from "./FallModal.vue?vue&type=script&lang=js"
export * from "./FallModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalSuchfunktionErgebnis: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/SuchfunktionErgebnis.vue').default,GlobalKommentarfunktionKommentar: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/kommentarfunktion/Kommentar.vue').default,Errormessage: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/Errormessage.vue').default,InternTelefonieFallAnrufe: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/telefonie/FallAnrufe.vue').default,GlobalDisplayFachbereichBadge: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/FachbereichBadge.vue').default,InternKalenderTerminContent: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/kalender/TerminContent.vue').default,InternKalenderTerminVertretungen: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/kalender/TerminVertretungen.vue').default})
