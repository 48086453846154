
export default {
  mixins: [],
  props: ['model_type', 'model_id', 'model'],
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    abrufen() {
      this.$$abrufen('schreiben', null, '/api/schreiben?model_type='+this.model_type+'&model_id='+this.model_id);
    }
  },
  mounted() {
    this.$$fill('schreiben', {with: ['zuordnungen.model']});
    this.abrufen();
    this.$nuxt.$on('store-schreiben', this.abrufen);
  },
  beforeDestroy() {
    this.$nuxt.$off('store-schreiben', this.abrufen);
  }

}
