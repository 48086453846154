
import faelle from '../../../mixins/faelle';
export default {
  mixins: [faelle],
  props: ['status', 'patient_id'],
  data() {
    return {
      page: 1,
      total: null,
    }
  },
  computed: {
    params() {
      let orderBy = {'order': 'desc'};
      // Offene Fälle aufsteigend, den Rest absteigend
      if (this.status === 0) {
        orderBy = {'updated_at': 'asc'}
      }
      let filter = [
          {where: ['status', this.status]}
        ];
      if (this.patient_id) {
        filter.push({where: ['betreffender_id', this.patient_id]});
      }
      return {filter: filter, with: ['letzteAenderungModel', 'betreffender', 'bearbeiter', 'termin'], perPage: 30, orderBy: orderBy};
    }
  },
  watch: {
    status(neu) {
      this.letzterClickSetzen();
    }
  },
  methods: {
    letzterClickSetzen() {
      this.$store.commit('telefonie/setLetzterClick', this.status);
    }
  },
  mounted() {
    this.letzterClickSetzen();
  }

}
