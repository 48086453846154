import { render, staticRenderFns } from "./AnrufZeile.vue?vue&type=template&id=34b4a3b9"
import script from "./AnrufZeile.vue?vue&type=script&lang=js"
export * from "./AnrufZeile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayDebugId: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/DebugId.vue').default,GlobalDisplayTelefon: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/Telefon.vue').default})
