import { render, staticRenderFns } from "./UrlaubTermine.vue?vue&type=template&id=47a9fd74"
import script from "./UrlaubTermine.vue?vue&type=script&lang=js"
export * from "./UrlaubTermine.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternKalenderAbsagestatusTermine: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/kalender/AbsagestatusTermine.vue').default})
