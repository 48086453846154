
export default {
  mixins: [],
  props: ['menuepunkte', 'suchfunktion'],
  data() {
    return {
      anzeigen: true,
      suchbegriff: '',
    }
  },
  computed: {
    menuepunkteFiltered() {
      if (!this.suchbegriff) return this.menuepunkte;
      return this.menuepunkte.filter(el => {
        console.log(el.bezeichnung, el.bezeichnung.includes(this.suchbegriff));

        return el.bezeichnung.toLowerCase().includes(this.suchbegriff.toLowerCase());
      });
    }
  },
  watch: {},
  methods: {
    navigate(menuepunkt) {
      this.$router.push(menuepunkt.route);
    },
  },
  mounted() {},

}
