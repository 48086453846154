import { render, staticRenderFns } from "./VerordnungStatus.vue?vue&type=template&id=5db53713"
import script from "./VerordnungStatus.vue?vue&type=script&lang=js"
export * from "./VerordnungStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayDebugId: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/DebugId.vue').default,GlobalDisplayFachbereichBadge: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/FachbereichBadge.vue').default,ModalsDetailsVerordnungTherapiestundenInformation: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/modals/details/VerordnungTherapiestundenInformation.vue').default,InternVerordnungenButtons: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/verordnungen/Buttons.vue').default})
