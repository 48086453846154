
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'anruf-starten',
      modaltitel: 'Anruf starten',
      event: 'display-anruf-starten',
      nummer: null,
      kontakt: null,
      anruf: null,
      betreffender: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    emptyAnruf() {
      this.anruf = {
        betreffender: null,
        telefonnummer: null,
        kontakt: null,
        bemerkung: null,
        faelle: [],
        neuerFall: null
      }
    },
    start(params) {
      this.$bvModal.hide(this.modalid);
      this.emptyAnruf();
      this.nummer = params.nummer;
      this.anruf.telefonnummer = params.nummer.nummer;
      this.anruf.kontakt = params.kontakt;
      this.betreffender = {model_id: params.betreffender.id, model_type: params.betreffender.model_type};
      this.anruf.betreffender = this.betreffender;
      this.faelleAbrufen();
      this.$bvModal.show(this.modalid);
    },
    anrufStarten() {
      if (!this.anruf.neuerFall && !this.anruf.faelle.length) return false;
      if (this.anruf.neuerFall && (!this.anruf.neuerFall.neuer_kommentar || !this.anruf.neuerFall.titel)) return false;
      this.$$store('anrufe', this.anruf).then(response => {
        this.$bvModal.hide(this.modalid);
        this.$success('Anruf gestartet');
      });
    },
    faelleAbrufen() {
      let filter = [{where: ['status', '!=', 2]}]; // Nicht erledigt]
      filter.push({where: ['betreffender_id', '=', (this.betreffender) ? this.betreffender.model_id : null]});
      filter.push({where: ['betreffender_type', '=', (this.betreffender) ? this.betreffender.model_type : null]});
      if (this.anruf.betreffender) {
      }
      this.$$fill('faelle', {filter: filter, with: ['kommentare']});
      this.$$abrufen('faelle').then(faelle => {
        if (!faelle || !faelle.length) this.neuerFall();
      })
    },
    neuerFall() {
      this.anruf.neuerFall = {
        neuer_kommentar: null,
        titel: null,
        betreffender_id: this.anruf.betreffender.id,
        betreffender_type: this.anruf.betreffender.model_type,
      };
    },
    updateBetreffender(betreffender) {
      if (betreffender) {
        this.anruf.betreffender = betreffender;
        this.faelleAbrufen();
      }
      else this.anruf.betreffender = {id: null, model_type: null};
    }
  },
  mounted() {
    this.$nuxt.$on(this.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.event, this.start);
  },

}
