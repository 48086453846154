import { render, staticRenderFns } from "./VerordnungPruefen.vue?vue&type=template&id=0aee121c"
import script from "./VerordnungPruefen.vue?vue&type=script&lang=js"
export * from "./VerordnungPruefen.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TooltipInformation: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/TooltipInformation.vue').default,GlobalFormulareFormularzeileFehlend: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/formulare/FormularzeileFehlend.vue').default,GlobalFormulareKontakt: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/formulare/Kontakt.vue').default,InternVerordnungenLeitsymptomatikFormular: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/verordnungen/LeitsymptomatikFormular.vue').default,GlobalFormularePrefillsVerordnungHeilmittel: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/formulare/prefills/VerordnungHeilmittel.vue').default,InternVerordnungenIcdCodesFormular: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/verordnungen/IcdCodesFormular.vue').default,GlobalFormularePrefillsVerordnungDiagnose: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/formulare/prefills/VerordnungDiagnose.vue').default})
