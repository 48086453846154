import { render, staticRenderFns } from "./Dokumentation.vue?vue&type=template&id=f536583a"
import script from "./Dokumentation.vue?vue&type=script&lang=js"
export * from "./Dokumentation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternPatientenTherapieziele: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/patienten/Therapieziele.vue').default,GlobalDisplayMitarbeiter: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/Mitarbeiter.vue').default,InternVerordnungenTherapiestundenDetailbuttons: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/verordnungen/therapiestunden/detailbuttons.vue').default})
