import { render, staticRenderFns } from "./VerordnungTherapiestundenInformation.vue?vue&type=template&id=4fe854ad"
import script from "./VerordnungTherapiestundenInformation.vue?vue&type=script&lang=js"
export * from "./VerordnungTherapiestundenInformation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TooltipInformation: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/TooltipInformation.vue').default})
