import { render, staticRenderFns } from "./SonstigeArbeitsstundeFormular.vue?vue&type=template&id=0f8317b0"
import script from "./SonstigeArbeitsstundeFormular.vue?vue&type=script&lang=js"
export * from "./SonstigeArbeitsstundeFormular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalFormulareAnfahrt: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/formulare/Anfahrt.vue').default})
