
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modalid: 'patientformular',
      modaltitel: 'Patient',
      patient: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(id) {
      if (id) {
        this.$$find('patienten', id).then(patient => {
          this.patient = patient;
        })
      }
      else {
        this.patient = {patientenkontakte: [],kontakt: null};
      }
      this.$bvModal.show(this.modalid);
    },
    done() {
      this.$bvModal.hide(this.modalid);
    },
    abbrechen() {
      this.$bvModal.hide(this.modalid);
    }
  },
  mounted() {
    this.$nuxt.$on('edit-patient', this.start);
    this.$nuxt.$on('create-patient', this.start);
  },
  beforeDestory() {
    this.$nuxt.$off('edit-patient', this.start);
    this.$nuxt.$off('create-patient', this.start);
  },

}
