
export default {
  mixins: [],
  props: [],
  data() {
    return {
      verordnung: null,
      id: "verordnung-abbrechen-modal",
    }
  },
  computed: {},
  watch: {},
  methods: {
    start(verordnung) {
      this.verordnung = verordnung;
      this.$bvModal.show(this.id);
    },
    abbrechen() {
      let send = this.$copy(this.verordnung);
      send.behandlungsabbruch = this.$moment().format('YYYY-MM-DD');
      this.$bvModal.hide(this.id)
      this.$$update('verordnungen', send, 'Verordnung erfolgreich abgebrochen').then(done => {
        this.$nuxt.$emit('display-verordnungen', send.patient_id);
      }, error => {
        this.$bvModal.show(this.id);
        this.$error('Verordnung abbrechen fehlgeschlagen',null, error);
      });
    }
  },
  mounted() {
    this.$nuxt.$on('display-'+this.id, this.start);
  },
  beforeDestroy() {
    this.$nuxt.$off('display-'+this.id, this.start);
  }

}
