import { render, staticRenderFns } from "./Neuaufnahme.vue?vue&type=template&id=2909d808"
import script from "./Neuaufnahme.vue?vue&type=script&lang=js"
export * from "./Neuaufnahme.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsPatientFormular: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/modals/details/PatientFormular.vue').default})
