import { render, staticRenderFns } from "./LaufenderAnrufDoku.vue?vue&type=template&id=8e05cbf2"
import script from "./LaufenderAnrufDoku.vue?vue&type=script&lang=js"
export * from "./LaufenderAnrufDoku.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayKontakt: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/display/Kontakt.vue').default,InternTelefonieAnrufKontakt: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/telefonie/AnrufKontakt.vue').default,GlobalSuchfunktionErgebnis: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/global/SuchfunktionErgebnis.vue').default,InternTelefonieAnrufFaelle: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/telefonie/AnrufFaelle.vue').default})
