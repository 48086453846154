import { render, staticRenderFns } from "./Kontakt.vue?vue&type=template&id=4cb71874"
import script from "./Kontakt.vue?vue&type=script&lang=js"
export * from "./Kontakt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsDetailsKontaktdaten: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/modals/details/kontaktdaten.vue').default})
