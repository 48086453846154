import { render, staticRenderFns } from "./Termin.vue?vue&type=template&id=e4a94b00"
import script from "./Termin.vue?vue&type=script&lang=js"
export * from "./Termin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InternKalenderTerminContent: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/kalender/TerminContent.vue').default,InternKalenderTerminTooltip: require('/Users/stefan/Dropbox/Stefan/gitrepos/gesundheitsportal/frontend/components/intern/kalender/TerminTooltip/index.vue').default})
