
import formularmodal from '~/mixins/formulare/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'termin-loeschen',
        event: ['delete::termin', 'restore::termin'],
        titel: 'Termin löschen',
        modelname: 'Termin',
        model: 'termine'
      },
      serieloeschen: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    loeschen() {
      this.$$delete('termine', this.model.id, null, {serie: this.serieloeschen}).then(response => {
        this.$nuxt.$emit('refresh-termine-'+this.$moment(this.model.von).format('YYYY-MM-DD'));
        this.$nuxt.$emit('update::termin', response)
        this.schliessen();
      });
    }
  },
  mounted() {

  }

}
