
export default {
  mixins: [],
  data() {
    return {
      id: 'termin-erstellen-'+this._uid,
      showError: false,
      termin: null,
      invalid: false,
      heilmittelKey: 0,
      dauerSpeicher: null,
      formkey: 0,
      termindauerZwischenspeicher: 0,
      terminAlt: null,
    }
  },
  computed: {
    bearbeiten() {
      if (this.termin && this.termin.created_at) return true;
      return false;
    }
  },
  methods: {
    updateTermintyp(termintyp) {
      this.termin.termintyp = termintyp;
      this.termin = this.resetTermin(this.termin);
      if (termintyp == 1) {
        this.updateTherapeut([this.$model]);
        this.formkey++;
      }
    },
    updateStandort(event) {
      this.termin.raum = null;
      if (event) this.termin.standort_id = event.id
      else this.termin.standort_id = null;
    },
    resetTermin(termin) {
      this.$set(termin, 'patienten', []);
      this.$set(termin, 'titel', null);
      this.$set(termin, 'ort_auswahl', null);
      this.$set(termin, 'ort', null);
      this.$set(termin, 'standort', null);
      this.$set(termin, 'standort_id', null);
      this.$set(termin, 'raum', null);
      this.$set(termin, 'raum_id', null);
      this.$set(termin, 'materialien', null);
      this.$set(termin, 'fahrzeuge', null);
      this.$set(termin, 'patient_id', null);
      this.$set(termin, 'hausbesuch', 0);
      this.$set(termin, 'parallelbehandlung', null);
      this.$set(termin, 'fachbereich', null);
      this.$set(termin, 'erstdiagnostik', false);
      this.$set(termin, 'bedarfsdiagnostik', false);
      this.$set(termin, 'puffertermin', false);
      this.$set(termin, 'projekt', false);
      this.$set(termin, 'heilmittel', null);
      return termin;
    },
    emptyTermin() {
      this.terminAlt = null;
      this.showError = false;
      this.invalid = false;
      let termin = {
        id: null,
        termintyp: 1,
        mitarbeiter: [this.$model],
        hospitationen: [],
        von: this.$moment().minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm'),
        bis: this.$moment().minutes(0).seconds(0).add(45, 'minutes').format('YYYY-MM-DDTHH:mm'),
        dauer: 45,
        dauerFixed: false,
        dauerWeitere: null,
        infos: null,
        serie: false,
        serie_anzahl: 10,
        serie_rhythmus: 1,
        erstdiagnostik: false,
        bedarfsdiagnostik: false,
        heilmittel: null,
      };
      this.resetTermin(termin);
      this.termin = termin;
      this.updateTherapeut([this.$model]);
      this.formkey++;
    },
    speichern() {
      if (this.invalid) {
        this.showError = true;
        return;
      }
      if (!this.termin.mitarbeiter.length || !this.termin.von || !this.termin.bis ||  this.termin.von >= this.termin.bis) {
        this.showError = true;
        return false;
      }
      if (this.termin.termintyp == 3 && !this.termin.projekt) {
        this.showError = true;
        return false;
      }
      this.$bvModal.hide(this.id);
      let send = this.$copy(this.termin);

      // Therapie Termin
      if (send.termintyp == 1) {
        send.titel = null;
        send.projekt = null;
      }
      // Sonstiges Termin
      else if (send.termintyp == 2) {
        send.fachbereich = null;
        send.patient_id = null;
      }
      if (send.serie && send.seriennachfolger) {
        send.seriennachfolger = send.seriennachfolger.map(el => el.id);
      }
      else delete send.seriennachfolger;

      if (send.ort_auswahl != 'Praxis') send.raum_id = null;
      else {
        send.ort = null;
        if (send.standort) send.standort_id = send.standort.id;
        if (send.raum) send.raum_id = send.raum.id;
      }
      if (send.heilmittel) send.heilmittel_id = send.heilmittel.id;

      this.$$store('termine', send, 'Termin gespeichert').then(response => {
        this.$nuxt.$emit('store-termin', response);
        let neuesDatum = this.$moment(response[0].von).format('YYYY-MM-DD');
        this.$nuxt.$emit('refresh-termine-'+neuesDatum);
        if (this.terminAlt) {
          let altesDatum = this.$moment(this.terminAlt.von).format('YYYY-MM-DD');
          if (altesDatum != neuesDatum) this.$nuxt.$emit('refresh-termine-'+altesDatum);
        }
        this.emptyTermin();
      }, error => {
        this.terminBearbeiten(send);
        this.$error('Termin erstellen fehlgeschlagen', null, error);
      })
    },
    show() {
      this.$root.$emit('bv::hide::tooltip');
    },
    updateTherapeut(event) {
      if (event && event[0]) {
        let therapeut = event[0].ist_therapeut;
        if (therapeut != null && therapeut != false) this.termin.fachbereich = therapeut;
      }
    },

    terminBearbeiten(termin) {
      termin = this.$copy(termin);
      if (termin.termintyp == 1) termin.dauerFixed = true;
      // Termin von und bis aufbereiten
      termin.von = this.$moment(termin.von).format('YYYY-MM-DDTHH:mm');
      termin.bis = this.$moment(termin.bis).format('YYYY-MM-DDTHH:mm');
      termin.serie_anzahl = 10;
      termin.serie_rhythmus = 1;
      // Termin Ort aufbereiten

      if (termin.hausbesuch == 1) termin.ort_auswahl = 'Anderer Ort';
      else if (termin.hausbesuch == 2) termin.ort_auswahl = 'Anderer Ort';
      else if (termin.ort) termin.ort_auswahl = 'Anderer Ort';
      else if (termin.raum_id || termin.standort_id) {
        termin.ort_auswahl = 'Praxis';
      }

      if (!termin.seriennachfolger) termin.seriennachfolger = [];
      this.termin = termin;
      this.terminAlt = this.$copy(termin);
      this.$bvModal.show(this.id);
    },
    terminErstellen(params = null) {
      if (params && params.model) {
        let termin = this.$fillMissing(params.model, this.termin);
        termin.id = null;
        termin.termintyp = null;
        this.termin = termin;
      }
      this.$bvModal.show(this.id);
    },
    formularSchliessen() {
      this.$bvModal.hide(this.id);
    },
  },
  mounted() {
    this.emptyTermin()
    this.$nuxt.$on('edit-kalender-termin', this.terminBearbeiten);
    this.$nuxt.$on('create-termin', this.terminErstellen);
    this.$nuxt.$on('close-termin-formular', this.formularSchliessen)
    this.$$abrufen('standorte');
  },
  beforeDestroy() {
    this.$nuxt.$off('edit-kalender-termin', this.terminBearbeiten);
    this.$nuxt.$off('create-termin', this.terminErstellen);
    this.$nuxt.$off('close-termin-formular', this.formularSchliessen)
  }
}
